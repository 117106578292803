import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/Home.vue';
import ExpressVPN from '../components/Expressvpn.vue';
import DesignSystem from '../components/DesignSystem.vue';
import B2BProducts from '../components/B2BProducts.vue';
import RoomControl from '../components/RoomControl.vue';
import ConceptsPage from '../components/ConceptsPage.vue';
import BankingApp from '../components/BankingApp.vue';
import MallNavigator from '../components/MallNavigator.vue';
import ArchivePage from '../components/Archive.vue';
import ResumePage from '../components/Resume.vue';
import NotFound from '../components/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { noindex: true, title: 'Home - Stan F' },
  },
  {
    path: '/work/expressvpn',
    name: 'ExpressVPN',
    component: ExpressVPN,
    meta: { noindex: true, title: 'ExpressVPN - Stan F' },
  },
  {
    path: '/work/design-system',
    name: 'DesignSystem',
    component: DesignSystem,
    meta: { noindex: true, title: 'Design System - Stan F' },
  },
  {
    path: '/work/b2b-products',
    name: 'B2BProducts',
    component: B2BProducts,
    meta: { noindex: true, title: 'B2B Products - Stan F' },
  },
  {
    path: '/work/room-control',
    name: 'RoomControl',
    component: RoomControl,
    meta: { noindex: true, title: 'Room Control - Stan F' },
  },
  {
    path: '/work/concepts',
    name: 'ConceptsPage',
    component: ConceptsPage,
    meta: { noindex: true, title: 'Concepts - Stan F' },
  },
  {
    path: '/work/banking-app',
    name: 'BankingApp',
    component: BankingApp,
    meta: { noindex: true, title: 'Banking App - Stan F' },
  },
  {
    path: '/work/mall-navigator',
    name: 'MallNavigator',
    component: MallNavigator,
    meta: { noindex: true, title: 'Mall Navigator - Stan F' },
  },
  {
    path: '/work/archive',
    name: 'ArchivePage',
    component: ArchivePage,
    meta: { noindex: true, title: 'Archive - Stan F' },
  },
  {
    path: '/resume',
    name: 'Resume',
    component: ResumePage,
    meta: { noindex: true, title: 'Resume - Stan F' },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: { title: '404 - Page Not Found' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // Always scroll to top when navigating to a new page
    return { top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  console.log('Navigating to:', to.name); // Add logging for debugging
  document.title = to.meta.title || 'Stan Familleza';
  let meta = document.querySelector('meta[name="robots"]');
  if (!meta) {
    meta = document.createElement('meta');
    meta.name = 'robots';
    document.head.appendChild(meta);
  }
  meta.content = to.meta.noindex ? 'noindex, nofollow' : 'index, follow';
  next();
});

export default router;