<template>
  <WorkLayout :project="project">
    <div class="content container">
      <section class="row">
        <div class="col col-sm-12">
          <img v-lazy="require('@/assets/images/sc/mood.png')" alt="Studio City Hotel App" />
        </div>
        <div class="col col-sm-6">
          <h6>Studio City's Melsuite design direction will reflect the vibrant, exciting, fun and prestige experience at Studio City.</h6>
        </div>
      </section>

      <section class="row">
        <div class="col col-sm-12">
          <img v-lazy="require('@/assets/images/sc/1.png')" alt="Studio City Hotel App" />
        </div>
        <div class="col col-sm-6">
          <h6>The look and feel was inspired by Studio City's architecture, highlighting the vibrant and colorful lights that illuminate the entire property.</h6>
        </div>
      </section>

      <section class="row">
        <div class="col col-sm-12">
          <img v-lazy="require('@/assets/images/sc/2.png')" alt="Studio City Hotel App" />
        </div>
        <div class="col col-sm-6">
          <h6>Using neutral colors keeps the user focused on his or her goal, while vibrant and dark colors allow the theme to stand out.</h6>
        </div>
      </section>

      <section class="row">
        <div class="col col-sm-12">
          <img v-lazy="require('@/assets/images/sc/3.png')" alt="Studio City Hotel App" />
        </div>
        <div class="col col-sm-6">
          <h6>Utilizing flexible and consistent typography and color palettes helps users easily understand their actions and navigate the interface.</h6>
        </div>
      </section>

      <section class="row">
        <div class="col col-sm-12">
          <img v-lazy="require('@/assets/images/sc/4.png')" alt="Studio City Hotel App" />
        </div>
        <div class="col col-sm-6">
          <h6>Incorporating a different but complementary background tone enhances the main content while adding vibrancy to the overall interface.</h6>
        </div>
      </section>
    </div>
  </WorkLayout>
</template>

<script>
  import WorkLayout from './WorkLayout.vue';

  export default {
    name: "RoomControl",
    components: {
    WorkLayout,
  },
  data() {
    return {
      project: {
        name: "Studio City Hotel App",
        description: "Designed the user experience for Studio City, a cinematic-themed resort offering a blend of luxury accommodations, world-class entertainment, and interactive attractions. The app enhances the guest experience with seamless navigation and intuitive features, reflecting the resort’s Hollywood-inspired atmosphere."
      }
    };
  },
  mounted() {
    document.title = `${this.project.name} - Stan Familleza`;
  },
};
</script>