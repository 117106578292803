import { inject } from '@vercel/analytics';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/styles/main.scss';
import VueLazyload from 'vue-lazyload';

const app = createApp(App);

inject();

// Register the vue-lazyload plugin globally
app.use(VueLazyload, {
  preLoad: 1.3,
  error: 'path/to/error.png', // Ensure the path is correct
  loading: require('@/assets/images/loading.svg'), // Ensure the path is correct
  attempt: 1
});

app.use(router);

app.mount('#app');