<template>
  <transition name="fade-out" @after-leave="removeEmoji">
    <div v-if="showEmoji" class="waving-emoji" aria-hidden="true">
      👋
    </div>
  </transition>
</template>

<script>
export default {
  name: 'WavingEmoji',
  data() {
    return {
      showEmoji: false,
      initialAnimationDuration: 1500, // Adjust as needed
      emojiDuration: 2000, // Adjust as needed
    };
  },
  mounted() {
    // Delay before showing the emoji
    setTimeout(() => {
      this.showEmoji = true;

      // Hide the emoji after its own animation
      setTimeout(() => {
        this.showEmoji = false;
      }, this.emojiDuration);
    }, this.initialAnimationDuration);
  },
  methods: {
    removeEmoji() {
      // Optional: Additional actions after the emoji disappears
    },
  },
};
</script>

  <style scoped>
  .waving-emoji {
    font-size: 4rem;
    position: absolute;
    top: -100px;
    left: 10%;
    animation: wave 2s ease-in-out;
    transform-origin: bottom right;
    z-index: 1000;
  }

  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    }
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  /* Fade-out transition */
  .fade-out-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-out-leave-to {
    opacity: 0;
  }
  </style>