<template>
  <div class="home">

    <section class="hero-section" id="hero">
      <div class="hero-content">
        <div class="container">
          <div class="row">
            <div class="col col-md-12">
              <WavingEmoji />
              <h1>{{ fname }} {{ lname }}</h1>
            </div>
          </div>
          <div class="row">
            <div class="col col-sm-8 col-md-7">
              <div class="intro">
                <p>I create user-centered digital experiences that are both intuitive and impactful. I’ve had the privilege
                  of working with startups and large companies across different industries. With a background in engineering
                  and strong visual skills, I ensure that my designs deliver real value to users and businesses alike.</p>
                <!-- <p>Welcome! <a href="#design-system">Take a look</a> at some of the projects I’ve worked on.</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="projects container">
      <div
        v-for="project in projects"
        :key="project.name"
        :id="project.id"
        class="project row"
      >
        <div class="text-column col col-sm-4 col-md-3">
          <div class="text">
            <!-- <div v-if="project.preText" class="pre-text">{{ project.preText }}</div> -->
            <h2 v-if="project.title">{{ project.title }}</h2>

            <!-- Render HTML content in the description -->
            <p v-if="project.description" v-html="project.description"></p>

            <p>
              <!-- First link -->
              <router-link v-if="project.link" :to="project.link" class="project-link">
                {{ project.linkText || 'View Project' }}
              </router-link>

              <!-- Second link (for external links or PDFs) -->
              <a v-if="typeof project.secondLink === 'string' && project.secondLink.endsWith('.pdf')"
                :href="project.secondLink"
                target="_blank"
                rel="noopener noreferrer"
                class="project-link">
                {{ project.secondLinkText || 'Download PDF' }}
              </a>

              <!-- Mailto link -->
              <a v-else-if="typeof project.secondLink === 'string' && project.secondLink.startsWith('mailto:')"
                :href="project.secondLink"
                class="project-link">
                {{ project.secondLinkText || 'More Info' }}
              </a>

              <span v-if="project.optionalText" class="optional-text">
                {{ project.optionalText }}
              </span>
            </p>
          </div>
        </div>
        <div class="image-column col col-sm-8 col-md-9">
          <router-link v-if="project.link" :to="project.link">
            <img v-lazy="project.image" :alt="project.title" :class="project.imageClass" />
          </router-link>
          <img v-else v-lazy="project.image" :alt="project.title" :class="project.imageClass" />
        </div>
      </div>
    </div>

  </div>

<!--

Here’s to the dreamers, the doers, and everyone in between.
Keep pushing forward, and don’t forget to enjoy the journey. 🍺

Stan

-->

</template>

<script>
import { siteConfig } from '@/config';
import WavingEmoji from './WavingEmoji.vue';

export default {
  name: "HomePage",
  components: {
    WavingEmoji,
  },
  data() {
    return {
      fname: siteConfig.fname,
      lname: siteConfig.lname,
      email: siteConfig.email,
      jobTitle: siteConfig.jobTitle,
      projects: []
    };
  },
  created() {
    this.projects = [
      {
        id: "design-system",  // Unique ID for each project
        title: "Design Systems",
        preText: "www.dtn.com",
        description: "Led the creation of a design system with components, recipes, and principles to support DTN’s products across various sectors, ensuring consistency and scalability.",
        optionalText: "Coming soon",
        image: require('@/assets/images/ds.png'),
        imageClass: 'tablet2',
      },
      {
        id: "b2b-products",
        title: "DTN Apps",
        preText: "Products compilation",
        description: "Contributed to the design and development of DTN’s products, enhancing user experience and supporting data-driven decisions across agriculture, energy, and weather sectors.",
        link: "/work/b2b-products",
        linkText: "See the screens",
        image: require('@/assets/images/apps.png'),
        imageClass: 'laptop',
      },
      {
        id: "room-control",
        title: "Studio City Hotel App",
        preText: "Studio City - Macau",
        description: "Designed an app allowing guests to control room settings, access hotel services, and shop seamlessly. Also developed the branding for one of the hotels, crafting a cohesive and premium experience that aligns with the resort’s luxurious atmosphere.",
        link: "/work/room-control",
        linkText: "View project",
        image: require('@/assets/images/sc/1.png'),
        imageClass: 'tablet',
      },
      {
        id: "website-app",
        title: "ExpressVPN",
        preText: "ExpressVPN",
        description: "Pioneered the UI design and engineering for ExpressVPN’s marketing website, crafting a durable and user-friendly interface that has remained effective and successful over the years.",
        optionalText: "expressvpn.com",
        // link: "https://expressvpn.com",
        // linkText: "expressvpn.com",
        image: require('@/assets/images/expressvpn/device.png'),
        imageClass: 'laptop',
      },
      {
        id: "concepts",
        title: "Concepts collection",
        preText: "Exploring possibilities",
        description: "A collection of concept designs showcasing innovative ideas and creative solutions, compiled to inspire and explore new possibilities in design.",
        optionalText: "Coming soon",
        image: require('@/assets/images/dating/s3.png'),
        imageClass: 'mobile',
      },
      {
        id: "banking-app",
        title: "Banking app UI",
        preText: "Avaloq",
        description: "Improved the usability and interface of a bank app by streamlining navigation, simplifying transactions, and enhancing accessibility, creating a more user-friendly experience.",
        link: "/work/banking-app",
        linkText: "View project",
        image: require('@/assets/images/avq/avq1.png'),
        imageClass: 'laptop',
      },
      {
        id: "mall-navigator",
        title: "Mall navigator",
        preText: "Yoho Mall - Hong Kong",
        description: "A customized mall map with store promotions, PWD paths, and quick access to customer service for easy navigation.",
        link: "/work/mall-navigator",
        linkText: "Explore the map",
        // secondLinkText: "Complete user flow",
        // secondLink: "/assets/pdf/yoho-navigator-user-flow.pdf", // Link to PDF in the public folder
        image: require('@/assets/images/yoho/yoho1.png'),
        imageClass: 'mobile',
      },
      {
        id: "archive",
        title: "A look back",
        preText: "From the Archives",
        description: "Explore a curated collection of past work, highlighting the early stages of my design journey and the evolution of style, technique, and creativity over the years.",
        optionalText: "Coming soon",
        image: require('@/assets/images/introme-app3.png'),
        imageClass: 'mobile',
      },
      {
        id: "info",
        title: 'Thanks for browsing!',
        preText: '',
        description: "I’m just a message away. If you’re interested in collaborating or would like to know more, feel free to reach out. Let’s create something exceptional together.",
        link: "/resume",
        linkText: "More about me",
        secondLink: `mailto:${this.email}`,
        secondLinkText: `${this.email}`,
        image: require('@/assets/images/stan.jpg'),
        imageClass: 'photo',
      },
    ];
  },
  mounted() {
    this.setupObserver();
    this.restoreScrollPosition();
  },
  methods: {
    setupObserver() {
      const options = {
        root: null, // In the viewport
        rootMargin: '0px',
        threshold: 0.5 // Trigger when 50% of the project is visible
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const projectId = entry.target.id;
            // Save the current project ID to the sessionStorage
            sessionStorage.setItem('currentProjectId', projectId);
          }
        });
      }, options);

      // Observe each project element
      this.$nextTick(() => {
        const projectElements = document.querySelectorAll('.project');
        projectElements.forEach((project) => {
          observer.observe(project);
        });
      });
    },

    restoreScrollPosition() {
      const savedProjectId = sessionStorage.getItem('currentProjectId');
      if (savedProjectId) {
        this.$nextTick(() => {
          const targetElement = document.getElementById(savedProjectId);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const currentProjectId = sessionStorage.getItem('currentProjectId');
    if (currentProjectId) {
      sessionStorage.setItem('savedProjectId', currentProjectId); // Save the project to return to
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const savedProjectId = sessionStorage.getItem('savedProjectId');
      if (savedProjectId) {
        vm.$nextTick(() => {
          const targetElement = document.getElementById(savedProjectId);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    });
  }
};
</script>