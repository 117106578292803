<template>
  <div class="work">
    <header class="header" :class="{ 'overlap-footer': isOverlappingFooter }">
      <nav>
        <router-link to="/" class="logo">
          <span>s</span>
        </router-link>
        <ul>
          <li><router-link to="/">← Back</router-link></li>
          <!-- <li><a href="javascript:void(0)" @click="scrollToFooter" class="scroll-to-footer">Projects</a></li> -->
        </ul>

      </nav>
    </header>

    <div class="hero">
      <div class="container">
        <div class="row">
          <div class="col col-sm-12">
            <h1 class="m-b-2">{{ project.name }}</h1>
          </div>
          <div class="col col-sm-8 ">
            <p>{{ project.description }}</p>
          </div>
        </div>
      </div>
    </div>

    <main>
      <slot></slot>
    </main>

    <footer class="footer" id="footer">
      <div class="container">
        <div class="row">
          <div class="col col-sm-6">
            <small>Selected projects:</small>
            <br>
            <ul>
              <li v-for="(project, index) in projects" :key="index">
                <router-link
                  :to="project.link"
                  class="footer-link"
                  active-class="active-link"
                >
                  <small>{{ project.company }}</small>
                  {{ project.title }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { siteConfig } from '@/config';

export default {
  name: "WorkLayout",
  props: {
    project: {
      type: Object,
      required: true,
      default() {
        return {
          name: "Default Project Name",
          description: "Default project description."
        };
      }
    }
  },
  data() {
    return {
      fname: siteConfig.fname,
      lname: siteConfig.lname,
      email: siteConfig.email,
      isOverlappingFooter: false,
      currentYear: new Date().getFullYear(),
      projects: [
        { company: "DTN", title: "Scalable design system", link: "/work/design-system" },
        { company: "DTN", title: "Agriculture, energy, tools", link: "/work/b2b-products" },
        { company: "Studio City", title: "Hotel App", link: "/work/room-control" },
        { company: "ExpressVPN", title: "Website", link: "/work/expressvpn" },
        { company: "Projects Compilation", title: "App collection", link: "/work/concepts" },
        { company: "Avaloq", title: "Banking app UI", link: "/work/banking-app" },
        { company: "Yoho Mall", title: "Mall navigator", link: "/work/mall-navigator" },
        { company: "Archives", title: "Past projects", link: "/work/archive" }
      ]
    };
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  methods: {
    setupIntersectionObserver() {
      const footer = document.querySelector('.footer');
      const header = document.querySelector('.header');

      if (!footer || !header) {
        console.warn('Footer or header not found in the DOM');
        return;
      }

      const checkFooterPosition = () => {
        const footerRect = footer.getBoundingClientRect();

        // Calculate the distance from the footer to the top of the viewport
        const distanceToTop = footerRect.top;

        // If the distance is 2rem (32px) or less from the top, add class, otherwise remove it
        if (distanceToTop <= 44) {
          if (!this.isOverlappingFooter) {
            this.isOverlappingFooter = true;
            header.classList.add('overlap-footer');
          }
        } else {
          if (this.isOverlappingFooter) {
            this.isOverlappingFooter = false;
            header.classList.remove('overlap-footer');
          }
        }
      };

      // Initial check in case the page is already scrolled
      checkFooterPosition();

      // Add an event listener for scroll to continuously check the position
      window.addEventListener('scroll', checkFooterPosition);
    },
    scrollToFooter() {
      const footerElement = document.getElementById('footer');
      if (footerElement) {
        footerElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
};
</script>